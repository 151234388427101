import { render, staticRenderFns } from "./Medallas.vue?vue&type=template&id=6c1fe5f4"
import script from "./Medallas.vue?vue&type=script&lang=js"
export * from "./Medallas.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports